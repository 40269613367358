









import { Component, Vue } from 'vue-property-decorator';

import Login from '@/components/Login.vue';

@Component({
  components: { Login },
})
export default class LoginModal extends Vue {
  $refs!: {
    loginForm: HTMLFormElement;
  };

  resetLogin() {
    // this.$refs.loginForm.resetLogin();
  }

  handleOk(evt) {
    evt.preventDefault();
    this.$refs.loginForm.handleSubmit();
  }
}
