






























































import { Component, Vue, Watch } from 'vue-property-decorator';
import LoginModal from '@/views/LoginModal.vue';
import { State, Action, namespace, Getter } from 'vuex-class';
import userService from '@/shared/userService';
import BootswatchThemeSelect from '@/components/BootswatchThemeSelect.vue';
import { LayoutActions, LayoutGetters } from '@/store/modules/layout';
import { BotStoreGetters } from '@/store/modules/ftbot';
import Favico from 'favico.js';
import { OpenTradeVizOptions, SettingsGetters } from '@/store/modules/settings';

const ftbot = namespace('ftbot');
const layoutNs = namespace('layout');
const uiSettingsNs = namespace('uiSettings');

@Component({
  components: { LoginModal, BootswatchThemeSelect },
})
export default class NavBar extends Vue {
  pingInterval: number | null = null;

  @State loggedIn!: boolean;

  @State isBotOnline!: boolean;

  @Action setLoggedIn;

  @Action loadUIVersion;

  @Getter getUiVersion!: string;

  @ftbot.Action ping;

  @ftbot.Action getState;

  @ftbot.Getter [BotStoreGetters.botName]: string;

  @ftbot.Getter [BotStoreGetters.openTradeCount]: number;

  @ftbot.Getter [BotStoreGetters.canRunBacktest]!: boolean;

  @layoutNs.Getter [LayoutGetters.getLayoutLocked]: boolean;

  @layoutNs.Action [LayoutActions.resetDashboardLayout];

  @layoutNs.Action [LayoutActions.resetTradingLayout];

  @layoutNs.Action [LayoutActions.setLayoutLocked];

  @uiSettingsNs.Getter [SettingsGetters.openTradesInTitle]: string;

  favicon: Favico | undefined = undefined;

  mounted() {
    this.ping();
    this.loadUIVersion();
    this.pingInterval = window.setInterval(this.ping, 60000);

    if (this.loggedIn) {
      // Query botstate - this will enable / disable certain modes
      this.getState();
    }
  }

  beforeDestroy() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  }

  logout(): void {
    userService.logout();
    this.setLoggedIn(false);
  }

  get layoutLockedLocal() {
    return this.getLayoutLocked;
  }

  set layoutLockedLocal(value: boolean) {
    this.setLayoutLocked(value);
  }

  setOpenTradesAsPill(tradeCount: number) {
    console.log('setPill', tradeCount);
    if (!this.favicon) {
      this.favicon = new Favico({
        animation: 'none',
        // position: 'up',
        // fontStyle: 'normal',
        // bgColor: '#',
        // textColor: '#FFFFFF',
      });
    }
    if (tradeCount !== 0 && this.openTradesInTitle === 'showPill') {
      this.favicon.badge(tradeCount);
    } else {
      this.favicon.reset();
      console.log('reset');
    }
  }

  resetDynamicLayout(): void {
    const route = this.$router.currentRoute.path;
    console.log(`resetLayout called for ${route}`);
    switch (route) {
      case '/trade':
        this.resetTradingLayout();
        break;
      case '/dashboard':
        this.resetDashboardLayout();
        break;
      default:
    }
  }

  setTitle() {
    let title = 'freqUI';
    if (this.openTradesInTitle === OpenTradeVizOptions.asTitle) {
      title = `(${this.openTradeCount}) ${title}`;
    }
    if (this.botName) {
      title = `${title} - ${this.botName}`;
    }
    document.title = title;
  }

  @Watch(BotStoreGetters.botName)
  botnameChanged() {
    this.setTitle();
  }

  @Watch(BotStoreGetters.openTradeCount)
  openTradeCountChanged() {
    console.log('openTradeCount changed');
    if (this.openTradesInTitle === OpenTradeVizOptions.showPill) {
      this.setOpenTradesAsPill(this.openTradeCount);
    } else if (this.openTradesInTitle === OpenTradeVizOptions.asTitle) {
      this.setTitle();
    }
  }

  @Watch(SettingsGetters.openTradesInTitle)
  openTradesSettingChanged() {
    this.setTitle();
    this.setOpenTradesAsPill(this.openTradeCount);
  }
}
