
















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AlertActions } from '@/store/modules/alerts';

const alerts = namespace('alerts');

@Component({})
export default class BotAlerts extends Vue {
  @alerts.State activeMessages;

  @alerts.Action [AlertActions.removeAlert];

  closeAlert() {
    this[AlertActions.removeAlert]();
  }
}
