






import { Component, Vue } from 'vue-property-decorator';
import NavBar from './NavBar.vue';

@Component({
  components: { NavBar },
})
export default class Header extends Vue {}
