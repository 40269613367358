







import { Component, Vue } from 'vue-property-decorator';
import BotAlerts from '@/components/ftbot/BotAlerts.vue';

@Component({
  components: { BotAlerts },
})
export default class Body extends Vue {}
